import React from 'react';

export const HeaderDataSource = {
  theme: {
    transparent: {
      wrapper: { className: 'header home-page-wrapper header-bg-transparent' },
      page: { className: 'home-page' },
      logo: {
        className: 'header-logo',
      },
      logoIcon: {
        className: 'header-logo-icon',
        children: require('./../assets/images/logo-header-icon-trans.png'),
      },
      logoText: {
        className: 'header-logo-text',
        children: require('./../assets/images/logo-header-text-trans.png')
      },
      Icon: {
        className: 'header-icon',
        children: [
          { name: 'wechat', icon: require('./../assets/icons/icon-wechat-trans.svg'), iconHover: require('./../assets/icons/icon-wechat-trans-selected.svg'), qrCode: require('./../assets/images/qrcode-wechat.jpg') },
          { name: 'mini', icon: require('./../assets/icons/icon-mini-trans.svg'), iconHover: require('./../assets/icons/icon-mini-trans-selected.svg'), qrCode: require('./../assets/images/qrcode-mini.jpg') },
          { name: 'weibo', icon: require('./../assets/icons/icon-weibo-trans.svg'), iconHover: require('./../assets/icons/icon-weibo-trans-selected.svg'), qrCode: require('./../assets/images/qrcode-weibo.jpg') }
        ]
      }
    },
    white: {
      wrapper: { className: 'header home-page-wrapper header-bg-white' },
      page: { className: 'home-page' },
      logo: {
        className: 'header-logo',
      },
      logoIcon: {
        className: 'header-logo-icon',
        children: require('./../assets/images/logo-header-icon-white.png'),
      },
      logoText: {
        className: 'header-logo-text',
        children: require('./../assets/images/logo-header-text-white.png')
      },
      Icon: {
        className: 'header-icon',
        children: [
          { name: 'wechat', icon: require('./../assets/icons/icon-wechat-white.svg'), iconHover: require('./../assets/icons/icon-wechat-white-selected.svg'), qrCode: require('./../assets/images/qrcode-wechat.jpg') },
          { name: 'mini', icon: require('./../assets/icons/icon-mini-white.svg'), iconHover: require('./../assets/icons/icon-mini-white-selected.svg'), qrCode: require('./../assets/images/qrcode-mini.jpg') },
          { name: 'weibo', icon: require('./../assets/icons/icon-weibo-white.svg'), iconHover: require('./../assets/icons/icon-weibo-white-selected.svg'), qrCode: require('./../assets/images/qrcode-weibo.jpg') }
        ]
      }
    }
  },
  Menu: {
    className: 'header-menu',
    children: [
      { name: 'item0', a: { children: '首页', href: '/home' } },
      { name: 'item1', a: { children: '企业概况', href: '/companyProfile' } },
      { name: 'item2', a: { children: '企业动态', href: '/companyNews' } },
      { name: 'item3', a: { children: '招商加盟', href: '/joinInvestment' } },
      { name: 'item4', a: { children: '特色产品', href: '/featuredProducts' } },
      { name: 'item5', a: { children: '人才招聘', href: '/jobOffer' } },
    ],
  },
  mobileMenu: { className: 'header-mobile-menu' },
};

export const FooterDataSource = {
  wrapper: { className: 'home-page-wrapper footer-wrapper' },
  OverPack: { className: 'home-page footer', playScale: 0.05 },
  content: {
    className: 'footer-content',
    contacts: [{
      icon: require('../assets/icons/icon-footer-phone.svg'),
      text: '029-86199666/029-88898000'
    }, {
      icon: require('../assets/icons/icon-footer-address.svg'),
      text: '中国·西安·长安北路54号太平洋大厦10层'
    }, {
      icon: require('../assets/icons/icon-footer-mail.svg'),
      text: 'edjingyingguanli@126.com'
    }
    ],
    links: [
      [{ text: '公司介绍', link: '/companyIntroduction' },
      { text: '管理团队', link: '/managerTeam' },
      { text: '企业荣誉', link: '/corporateHonor' }],
      [{ text: '招商加盟', link: '/joinInvestment' },
      { text: '企业资讯', link: '/informationList/1' },
      { text: '档期活动', link: '/companyNews' }],
      [{ text: '特色产品', link: '/featuredProducts' },
      { text: '企业文化', link: '/corporateCulture' },
      { text: '人才招聘', link: '/jobOffer' }]
    ],
    logo: (
      <section className="footer-logo">
        <img src={require('./../assets/images/logo-header-icon-trans.png')} alt='logo' style={{ height: '48px' }} />
        <span>每一天，为明天!</span>
      </section>
    )
  },
  copyright: {
    className: 'footer-bar',
    children: (
      <span>
        Copyright 2020 西安每一天便利超市连锁有限公司 All Right Reserved. <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">陕ICP备14007509号</a>
      </span>
    ),
  },


};
