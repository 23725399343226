import React from 'react'
import { Popover } from 'antd';
import Sidebar from './Sidebar'

export default class Toolbar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            sidebarVisiable:false
        }
        this.openSidebar = this.openSidebar.bind(this)
        this.closeSidebar =  this.closeSidebar.bind(this)
    }
    openSidebar(){
        this.setState({
            sidebarVisiable :true
        })
    }
    closeSidebar(){
        this.setState({
            sidebarVisiable:false
        })
    }
    render(){
        return (
            <div className='toolbar-wrapper'>
                <div>
                    <Popover content={<span>客服热线：18092680365</span>} placement='left'>
                        <div className='toolbar-item'>
                            <img src={require('./../assets/icons/icon-service.svg')} alt="service"/>
                        </div>
                    </Popover>
                </div>
                <div>
                    <Popover content={<span>投诉与建议</span>} placement='left'>
                        <div className='toolbar-item' onClick={this.openSidebar}>
                            <img src={require('./../assets/icons/icon-advice.svg')} alt="service"/>
                        </div>
                    </Popover>
                </div>
                <div className="toolbar-top" onClick={_=>{
                    window.scrollTo(0,0)
                }}>
                    <Popover content={<span>回到顶部</span>} placement='left'>
                    <div className='toolbar-item'>
                        <img src={require('./../assets/icons/icon-top.svg')} alt="back to top"/>
                    </div>
                    </Popover>
                </div>
                <Sidebar visiable={this.state.sidebarVisiable} close={_=>{this.closeSidebar()}}></Sidebar>
            </div>
        )
    }
}