import React from 'react'
import { Drawer, Form, Button, message, Input } from 'antd'
import axios from 'axios'
import {ENVIRONMENT} from '../ENVIRONMENT'
const Item = Form.Item
const TextArea = Input.TextArea
class SidebarCom extends React.Component {
  constructor(props) {
    super(props)
    this.onClose = this.onClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  onClose() {
    this.props.close()
  }

  handleSubmit(e){
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        axios.post(`${ENVIRONMENT.BASE_URL}/website/messageBoard/insertWebsiteMessageBoard`, {
          issueType: 2,
          name: values.username,
          mobilePhone: values.phone,
          messageContent: values.message,
        }).then((res) => {
          if (res && res.data && res.data.success) {
            message.success(res.data.message);
            this.props.form.resetFields();
          } else {
            message.error(res.data.message);
          }
        }).catch( error => {
            message.error('网络异常，请稍后重试~');
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Drawer
        width={250}
        onClose={this.onClose}
        visible={this.props.visiable}
        closable={false}
        className="sidebar-swipper"
      >
        <div className="sidebar-button-hide" onClick={this.onClose}></div>
        <div className="sidebar-bg">
          <img
            src={require('./../assets/images/sidebar-advice.png')}
            alt="advice-icon"
          />
        </div>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: '请输入您的姓名' }]
            })(<Input placeholder="您的姓名" />)}
          </Item>
          <Item>
            {getFieldDecorator('phone', {
              rules: [{ required: true, message: '请输入您的联系方式' }]
            })(<Input placeholder="您的联系方式" />)}
          </Item>
          <Item>
            {getFieldDecorator('message', {
              rules: [{ required: true, message: '请输入您的留言内容' }]
            })(<TextArea placeholder="在线留言" className="sidebar-msg-box" />)}
          </Item>
          <Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              提交
            </Button>
          </Item>
        </Form>
      </Drawer>
    )
  }
}

const Sidebar = Form.create({ name: 'advice_feedback' })(SidebarCom)
export default Sidebar
