import React from 'react'
import Home from './home';
// import BusinessIntroduction from './business-introduction/BusinessIntroduction';
// import CompanyNews from './company-news';
// import CompanyProfile from './company-profile/CompanyProfile';
// import CompanyIntroduction from './company-introduction-temp/CompanyIntroduction';
// import CorporateHonor from './company-introduction-temp/CorporateHonor';
// import CorporateCulture from './company-introduction-temp/CorporateCulture';
// import EnterpriseInfoMation from './enterprise-infomation/EnterpriseInfoMation';
// import FeaturedProducts from './featured-products/FeaturedProducts';
// import InformationList from './information-list/InformationList';
// import JobOffer from './job-offer/JobOffer';
// import JobOfferList from './job-offer/JobOfferList';
// import JoinInvestment from './join-investment';
// import ManagerTeam from './manager-team/ManagerTeam';
const BusinessIntroduction = React.lazy(_=>import('./business-introduction/BusinessIntroduction'));
const CompanyNews = React.lazy(_=>import('./company-news'));
const CompanyProfile = React.lazy(_=>import('./company-profile/CompanyProfile'));
const CompanyIntroduction = React.lazy(_=>import('./company-introduction-temp/CompanyIntroduction'));
const CorporateHonor = React.lazy(_=>import('./company-introduction-temp/CorporateHonor'));
const CorporateCulture = React.lazy(_=>import('./company-introduction-temp/CorporateCulture'));
const EnterpriseInfoMation = React.lazy(_=>import('./enterprise-infomation/EnterpriseInfoMation'));
const FeaturedProducts = React.lazy(_=>import('./featured-products/FeaturedProducts'));
const InformationList = React.lazy(_=>import('./information-list/InformationList'));
const JobOffer = React.lazy(_=>import('./job-offer/JobOffer'));
const JobOfferList = React.lazy(_=>import('./job-offer/JobOfferList'));
const JoinInvestment = React.lazy(_=>import('./join-investment'));
const ManagerTeam = React.lazy(_=>import('./manager-team/ManagerTeam'));

const AppRouters = [
    {path: "/", name: "root", exact:true, redirect:'/home'},
    {path: "/home", name: "home", component: Home, exact:true, redirect:null},
    {path: "/companyProfile", name: "companyProfile", component: CompanyProfile, exact:true, redirect:null},
    {path: "/informationList/:businessType", name: "informationList", component: InformationList, exact:true, redirect:null},
    {path: "/enterpriseInfomation/:type:id", name: "enterpriseInfomation", component: EnterpriseInfoMation, exact:true, redirect:null},
    {path: "/jobOffer", name: "jobOffer", component: JobOffer, exact:true, redirect:null},
    {path: "/jobOfferList/:info", name: "jobOfferList", component: JobOfferList, exact:true, redirect:null},
    {path: "/joinInvestment", name: "joinInvestment", component: JoinInvestment, exact:true, redirect:null},
    {path: "/companyNews", name: "companyNews", component: CompanyNews, exact:true, redirect:null},
    {path: "/featuredProducts", name: "featuredProducts", component: FeaturedProducts, exact:true, redirect:null},
    {path: "/companyIntroduction", name: "companyIntroduction", component: CompanyIntroduction, exact:true, redirect:null},
    {path: "/corporateCulture", name: "corporateCulture", component: CorporateCulture, exact:true, redirect:null},
    {path: "/businessIntroduction", name: "businessIntroduction", component: BusinessIntroduction, exact:true, redirect:null},
    {path: "/corporateHonor", name: "corporateHonor", component: CorporateHonor, exact:true, redirect:null},
    {path: "/managerTeam", name: "managerTeam", component: ManagerTeam, exact:true, redirect:null},
    {path: "*", name: "wildcard", exact:false, redirect:'/home'}
]

export default AppRouters