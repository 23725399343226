import React, { Component, Suspense } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './common-layout/Header';
import Footer from './common-layout/Footer';
import Toolbar from './common-layout/Toolbar';

import AppRouters from './App.router'

import {
  HeaderDataSource,
  FooterDataSource,
} from './common-layout/data.source.js';

import './common-layout/less/layout.less'


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      theme: 'white',
      showToolbar: true,
      showFooter: true,
      headerDataSource: {...HeaderDataSource,...HeaderDataSource.theme['white']},
    };
    this.setLayout = this.setLayout.bind(this)
  }
  componentDidMount () {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  setLayout(conf){
    this.setState({
      headerDataSource: {...HeaderDataSource,...HeaderDataSource.theme[conf.theme || 'white']},
      showToolbar: conf.showToolbar,
      showFooter: conf.showFooter
    })
  }
  render () {
    // ！important ：<Route>标签需要用 <Switch> 包裹
    // https://reacttraining.com/react-router/web/api/Switch
    return (
      <Router>
        <div className="main-body">
          <Header dataSource={this.state.headerDataSource} isMobile={this.state.isMobile} />
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              {
                AppRouters.map(routerItem => {
                  let item = {...routerItem}
                  delete item.component
                  delete item.name
                  return (
                    item.redirect?
                    <Route key={routerItem.name} {...item} ><Redirect to={item.redirect} /></Route>:
                    <Route key={routerItem.name} {...item} render={(props) => { //跳转前的一些操作（纯函数，不可修改state）;props 不能省略，否则取不到参数
                      window.scrollTo(0, 0)
                      return <routerItem.component {...props} setLayout={conf=>{this.setLayout(conf)}} />
                    }} />
                  )
                })
              }
            </Switch>
          </Suspense>
          {this.state.showFooter?(<Footer dataSource={FooterDataSource}></Footer>):null}
          {this.state.showToolbar?( <Toolbar />):null}
        </div>
      </Router>
    );
  }
}

export default App;