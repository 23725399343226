/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import Video from './Video'
import BackgroundFirst from './BackgroundFirst'
import BackgroundSecond from './BackgroundSecond'
import BackgroundThird from './BackgroundThird'
import BannerAnim, { Element } from 'rc-banner-anim'
import 'rc-banner-anim/assets/index.css'

import './less/index.less'
const BgElement = Element.BgElement

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      autoPlay:false
    }
    this.onHideVideo = this.onHideVideo.bind(this)
  }
  componentDidMount() {
    this.props.setLayout({ theme: 'transparent' })
  }

  onHideVideo(){
    this.setState({
      autoPlay:true
    })
  }

  render() {
    return (
      <div>
        <Video key="Video" onHideVideo={_=>{this.onHideVideo()}}></Video>
        <BannerAnim
          type="verticalOverlay"
          thumb={false}
          autoPlay={this.state.autoPlay}
          arrow={true}
          duration={800}
          className="banner-box"
        >
          <Element key="bg-box-1">
            <BgElement
              key="bg1"
              style={{
                backgroundImage: `url('https://tseveryday.com/websiteMedia/home/home_bg_1.jpg')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                width: '100%',
                height: '100%'
              }}
            />
            <BackgroundThird />
          </Element>
          <Element key="bg-box-2">
            <BgElement
              key="bg2"
              style={{
                backgroundImage: `url('https://tseveryday.com/websiteMedia/home/home_bg_5.png')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                width: '100%',
                height: '100%'
              }}
            />
            <BackgroundFirst />
          </Element>
          <Element key="bg-box-3">
            <BgElement
              key="bg3"
              style={{
                backgroundImage: `url('https://tseveryday.com/websiteMedia/home/home_bg_3.jpg')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                width: '100%',
                height: '100%'
              }}
            />
            <BackgroundSecond />
          </Element>
        </BannerAnim>
        <div className="indexBottomStyle"> Copyright 2020
      西安每一天便利超市连锁有限公司 All Right Reserved. <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" className="indexBottomColor" target="_blank">陕ICP备14007509号</a></div>

      </div>
    )
  }
}
