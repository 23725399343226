/**
 * TODO:waiting for the offical solution
 * List all antd icons you want to use in your source code
 * https://github.com/ant-design/ant-design/issues/12011#issuecomment-549652300
 * 注：如果打包错误，请检查alias的配置和icons.ts的路径
 * icon.ts的内容，包括自己用到的icon和组件用到的icon
 */
export {
    default as UserOutline
} from '@ant-design/icons/lib/outline/UserOutline';

export {
    default as MobileOutline
} from '@ant-design/icons/lib/outline/MobileOutline';

export {
    default as MailOutline
} from '@ant-design/icons/lib/outline/MailOutline';

export {
    default as EnvironmentOutline
} from '@ant-design/icons/lib/outline/EnvironmentOutline';

export {
    default as MessageOutline
} from '@ant-design/icons/lib/outline/MessageOutline';


// 开发选址类    <Icon  type="environment"  />
// 门店开业筹备类    <Icon  type="appstore"  />
// 门店运营类    <Icon  type="shop"  />
// 品牌运营类    <Icon  type="pie-chart"  />
// 商品及配送类    <Icon  type="shopping"  />
// 财务类    <Icon  type="account-book"  />
// 人事类    <Icon  type="user"  />+-
// 信息技术支持类    <Icon  type="laptop"  />
// 其他职能类    <Icon  type="file-search"  />

export {
    default as AppstoreOutline
} from '@ant-design/icons/lib/outline/AppstoreOutline';

export {
    default as ShopOutline
} from '@ant-design/icons/lib/outline/ShopOutline';

export {
    default as PieChartOutline
} from '@ant-design/icons/lib/outline/PieChartOutline';

export {
    default as ShoppingOutline
} from '@ant-design/icons/lib/outline/ShoppingOutline';

export {
    default as AccountBookOutline
} from '@ant-design/icons/lib/outline/AccountBookOutline';

export {
    default as LaptopOutline
} from '@ant-design/icons/lib/outline/LaptopOutline';

export {
    default as FileSearchOutline
} from '@ant-design/icons/lib/outline/FileSearchOutline';