import React from 'react'
import { Link } from 'react-router-dom'

import Texty from 'rc-texty'
import QueueAnim from 'rc-queue-anim'

export default function BackgroundSecond() {
  return (
    <div className="bg-second-container">
      <div className="content-box">
          <div>
            <Texty duration='200' type="bottom" className="introduce">
              追梦，
            </Texty>
            <Texty duration='400' type="bottom" delay={200} className="introduce">
              有每一天与你同行
            </Texty>
          </div>
          <QueueAnim
          animConfig={
            { opacity:[1, 0], translateY: [0, 80] } } delay={600}
        >
          <div key="go" style={{marginTop:'20px'}}>
          <Link to='/joinInvestment'>
            <button className="button">加入我们</button>
          </Link>
          </div>
        </QueueAnim>
      </div>
    </div>
  )
}
