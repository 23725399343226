import React from 'react'
import { Link } from 'react-router-dom'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
class Footer extends React.PureComponent {
  render() {
    const { ...props } = this.props
    const { dataSource } = props
    delete props.dataSource
    delete props.isMobile
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <div className="footer-content">
            <section style={{ color: '#fff' }}>
              {dataSource.content.contacts.map((item,index) => (
                <p key={index}>
                    <img src={item.icon} alt="icon" className='footer-icon'/>
                    <span key={index}>{item.text}</span>
                </p>
              ))}
            </section>
            {dataSource.content.links.map((item,index)=>(
              <section key={index}>
                {item.map((childItem,childIndex)=>(
                  <div key={`${index}_${childIndex}`}><Link to={childItem.link} className="footer-link">{childItem.text}</Link></div>
                ))}
              </section>
            ))}
            {dataSource.content.logo}
          </div>

        </OverPack>
      </div>
    )
  }
}

export default Footer
