import React from 'react'
import { Link } from 'react-router-dom'

import QueueAnim from 'rc-queue-anim'
import Texty from 'rc-texty'
const cssPrefix = 'bg-third'

export default class BackgroundThird extends React.Component {
  render () {
    return (
      <div className={`${cssPrefix}-container`}>
        <div className={`${cssPrefix}-content-box`}>
          <Texty type="alpha" className={`${cssPrefix}-title`} key="title">
            每一天，为明天！
          </Texty>
          <QueueAnim
            duration={600}
            animConfig={{ opacity: [1, 0], translateY: [0, 50] }}
            delay={300}
          >
            <div key="button" style={{ marginTop: '20px' }}>
              <Link to="/companyProfile">
                <button className={`${cssPrefix}-button`}>了解我们</button>
              </Link>
            </div>
          </QueueAnim>
        </div>
      </div >
    )
  }
}
