import React from 'react'
import { findDOMNode } from 'react-dom'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import TweenOne from 'rc-tween-one'

const Item = Menu.Item

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneOpen: false,
      menuHeight: 0,
      qrCode: '',
      showQrcode: false
    }
    this.iconHover = this.iconHover.bind(this)
    this.iconLeave = this.iconLeave.bind(this)
  }
  iconHover(e,item){
    e.target.src = item.iconHover
    this.setState({
      showQrcode :true,
      qrCode: item.qrCode
    })
  }

  iconLeave(e,item){
    e.target.src = item.icon;
    this.setState({
      showQrcode :false
    })
  }

  phoneClick = () => {
    const menu = findDOMNode(this.menu)
    const phoneOpen = !this.state.phoneOpen
    this.setState({
      phoneOpen,
      menuHeight: phoneOpen ? menu.scrollHeight : 0
    })
  }

  render() {
    const { ...props } = this.props
    let { dataSource, isMobile } = props
    delete props.dataSource
    delete props.isMobile
    const { menuHeight, phoneOpen } = this.state
    const navData = dataSource.Menu.children
    const navChildren = Object.keys(navData).map((key, i) => (
      <Item key={i.toString()} {...navData[key]}>
        <Link
          {...navData[key].a}
          to={navData[key].a.href}
          target={navData[key].a.target}
        >
          {navData[key].a.children}
        </Link>
      </Item>
    ))
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
          >
            <img src={dataSource.logoIcon.children} alt="每一天logo" className={dataSource.logoIcon.className} />
            <img src={dataSource.logoText.children} alt="每一天便利店" className={dataSource.logoText.className} />
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick()
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={{ x: 30, type: 'from', ease: 'easeOutQuad' }}
            ref={c => {
              this.menu = c
            }}
            style={isMobile ? { height: menuHeight } : null}
          >
            <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              defaultSelectedKeys={['0']}
              theme={isMobile ? 'dark' : 'default'}
            >
              {navChildren}
            </Menu>
          </TweenOne>
          <div className={dataSource.Icon.className}>
            <ul>
              {
                dataSource.Icon.children.map((item,index)=>
                    <li key={index}>
                      <img src={item.icon} alt={item.name} 
                      onMouseEnter={(e)=>this.iconHover(e,item)} 
                      onMouseLeave={e=>this.iconLeave(e,item)}/>
                    </li>
                )
              }
              <img src={this.state.qrCode} className='qr-code' style={{display:this.state.showQrcode?'inline-block':'none'}} alt='二维码' />
            </ul>
          </div>
        </div>
      </TweenOne>
    )
  }
}

export default Header
