import React from 'react'
class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isToggleOn: true };
        // 为了在回调中使用 `this`，这个绑定是必不可少的
        this.skipVideo = this.skipVideo.bind(this);
    }
    skipVideo () {
        let videoBox = document.getElementById("video-full-page")
        videoBox.removeChild(videoBox.childNodes[0])
        document.getElementsByClassName("video-full-page")[0].style.opacity = "0"
        this.props.onHideVideo()
    }
    render () {
        return (
            <div id="video-full-page" className="video-full-page" onTransitionEnd={_=>{document.getElementById('video-full-page').style.display='none'}} >
                <div className="video-player-box" id="video-player-box">
                    <span className="video-skip-button" onClick={this.skipVideo}>跳过</span>
                    <video controls autoPlay="autoplay" className="video-tech" muted
                    onEnded={this.skipVideo}
                    src="https://tseveryday.com/websiteMedia/video/0101010.mp4" ></video>
                </div>
            </div>
        )
    }
}
export default Video;