import React from 'react'
import { Link } from 'react-router-dom'

import QueueAnim from 'rc-queue-anim'
const cssPrefix = 'bg-first'
export default function BackgroundFirst() {
  return (
    <div className={`${cssPrefix}-container`}>
      <div className={`${cssPrefix}-content-box`}>
        <QueueAnim
          duration={800}
          animConfig={{ opacity: [1, 0], translateY: [0, 50] }}
        >
          {/* <h2 className={`${cssPrefix}-title`} key='title'>营养早餐</h2> */}
          <h2 key='title'>
          <svg width="250px" height="70px">
            <defs>
              <linearGradient id="PSgrad_0" x1="0%" x2="0%" y1="0%" y2="100%">
                <stop
                  offset="0%"
                  stop-color="rgb(56,249,215)"
                  stop-opacity="1"
                />
                <stop
                  offset="100%"
                  stop-color="rgb(67,233,123)"
                  stop-opacity="1"
                />
              </linearGradient>
            </defs>
             <text x="0" y="60" fill="url(#PSgrad_0)" style={{fontSize:'60px'}}>营养早餐</text>
          </svg>
          </h2>
          <p className={`${cssPrefix}-introduce`} key="intro">
            给每一天不一样的你
          </p>
          <div key="button" style={{ marginTop: '20px' }}>
            <Link to="/featuredProducts">
              <button className={`${cssPrefix}-button`}>特色产品</button>
            </Link>
          </div>
        </QueueAnim>
      </div>
    </div>
  )
}
